$h1: 2.062rem;
$h2: 1.750rem;
$h3: 1.438rem;
$h4: 1.188rem;
$base: 1rem;

h1 {font-size: $h1; @apply font-bold;}
h2 {font-size: $h2; @apply font-bold;}
h3 {font-size: $h3;}
h4 {font-size: $h4;}
p {font-size: $base;}

@screen 2xl {
  h1 {font-size: calc($h1 * 1.25);}
  h2 {font-size: calc($h2 * 1.25);}
  h3 {font-size: calc($h3 * 1.25);}
  h4 {font-size: calc($h4 * 1.25);}
  p {font-size: calc($base * 1.25);}
}