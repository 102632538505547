@import './tailwind.scss';
@import './Typography.scss';
@import './Variables.scss';

body {
  @apply font-sofiaPro bg-beige text-text overflow-hidden;
  background-image: url('../../images/bg.svg');
  background-size: 180%;
  @screen md {
    background-size: 150%;
  }
  @screen lg {
    background-size: 100%;
  }
  & > div {
    @apply flex flex-col overflow-scroll;
    height: $viewHeight;
  }
}

// Wrapper
.wrapper {
  @apply flex-grow;
  @screen lg {
    @apply pt-20;
    height: $viewHeight;
  }
}

// Navigation
button.toggleNav .icon {
  transform: translate(-50%, -50%) rotate(-5deg);
  margin-top: -2px;
  @screen lg {
    margin-top: -4px;
  }
}

nav {
  height: calc(var(--vh, 1vh) * 100);
  .menu {
    a, .menu-item {
      @apply flex items-center;
      font-size: calc($base * 1.15);
      svg, .icon {
        @apply mr-4;
      }
      & + a, & + .menu-item {
        @apply mt-3;
      }
    }
    @apply h-auto w-11/12 lg:w-full;
    max-width: 575px;
    background-image: url('../../images/menu.svg');
    @screen lg {
      background-image: url('../../images/menu-lg.svg')
    }
    @screen 3xl {
      a, .menu-item {
        font-size: calc($base * 1.25);
      }
      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}

.item, .menu-item {
  @apply rounded-full border-4 border-brown h-16 px-6 flex items-center font-bold;
}

.menu-item {
  @apply bg-beige cursor-pointer transition-transform 3xl:text-lg;
  &:hover {
    @apply rotate-1;
  }
}