.leaderboard {
  @apply w-full px-6;
  .losers, .podium {
    max-width: 575px;
    @apply mx-auto;
  }
  .participant {
    @apply mb-2 relative pl-2;
    .data {
      @apply flex items-center px-6 py-4 w-full border-4 border-brown rounded-full font-bold bg-beige-dark lg:text-2xl;
      max-height: 64px;
      .place {
        @apply w-10;
      }
      .user {
        @apply flex-grow;
      }
      .creator {
        @apply relative mr-4 flex items-end;
        @screen lg {
          @apply mr-6;
        }
        span {
          @apply w-4;
          @screen lg {
            @apply w-6;
          }
          svg {
            @apply w-6 h-auto;
            @screen lg {
              @apply w-9;
            }
          }
        }
      }
    }
    &.place {
      &-1, &-2, &-3, &-4, &-5 {
        .data {
          @apply pl-16;
        }
        .place {
          @apply w-11 absolute left-0;
          @screen lg {
            @apply w-11;
          }
          svg {
            @apply w-inherit h-inherit;
          }
        }
      }
    }
  }
  @screen lg {
    .podium {
      max-width: 696px;
      @apply flex mb-16 relative w-full;
      .participant {
        @apply pl-0 m-0 w-1/3 flex flex-col items-center;
        .data {
          @apply flex-col items-center justify-center rounded-3xl p-6 pt-0 pb-7 w-full;
          max-height: none;
          .place {
            @apply relative w-16 h-20 -translate-y-6;
          }
          .user {
            @screen lg {
              word-spacing: 100vw;
            }
            @apply text-3xl text-center -translate-y-4;
          }
          .score {
            @apply text-2xl;
          }
          .creator {
            @apply m-0 absolute bottom-0 translate-y-3;
            @screen lg {
              @apply m-0;
            }
            span {
              @apply w-10;
              svg {
                @apply w-10;
              }
              & + span {
                @apply -ml-3;
              }
            }
          }
        }
        &.place {
          &-1 {
            @apply px-3 absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4;
            .data {
              .place {
                @apply w-20 h-24;
              }
              .user {
                @apply text-4xl;
              }
            }
          }
          &-2 {
            @apply pr-3;
          }
          &-3 {
            @apply pl-3 absolute right-0;
          }
        }
      }
    }
  }
}

.no-played {
  @apply mx-6;
  h1 {
    background-image: url('../../images/menu.svg');
    max-width: 475px;
    min-height: 12rem;
    @apply mx-auto p-6 lg:px-12 text-2xl text-center flex justify-center items-center;
  }
  @screen lg {
    h1 {
      min-height: 15rem;
    }
  }
}