@import url("https://use.typekit.net/ohh2kiy.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .absolute-center {
    @apply absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4;
  }
}
