.page {
  @apply pb-10 px-2 lg:-mt-8 mx-auto flex flex-col items-center w-auto h-full;
  & > div {
    @apply w-full h-full max-w-xl 2xl:max-w-2xl bg-beige rounded-2xl border-4 border-brown p-6 md:p-10;
  }
  h1 {
    @apply mb-4 font-semibold text-brown;
    b {
      @apply font-semibold;
    }
  }
  ol {
    li {
      @apply mb-4 flex align-top;
      counter-increment: item;
      &::marker {
        @apply font-bold text-xl;
      }
      &:before {
        @apply inline-block pr-3 font-extrabold text-right w-8 text-yellow text-2xl;
        content: counter(item) ".";
      }
    }
  }
  ul {
    @apply list-disc pl-4;
    li {
      @apply mb-4;
    }
  }
  a {
    @apply font-semibold text-brown underline;
    text-underline-offset: 1px;
    &:hover {
      text-underline-offset: 2px;
    }
  }
}