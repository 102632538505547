@import './Variables.scss';

.winner {
  @apply relative flex flex-col items-center flex-grow w-full h-full;
  .plaque {
    @apply absolute text-center;
    background: url('../../images/plate.svg') no-repeat center;
    background-size: 100%;
    @apply w-72 h-44 flex flex-col justify-center items-center -translate-y-36;
  }
  .pedestal {
    @apply flex-grow w-full overflow-hidden;
    svg {
      width: 115%;
      @apply relative left-2/4 -translate-x-2/4 max-w-md;
    }
  }
  & + .winner {
    margin-top: -20rem;
    &:last-child {
      .pedestal {
        @apply max-h-56;
      }
    }
  }
  @screen lg {
    @apply w-1/2;
    &:first-child {
      @apply w-full;
    }
    & + .winner {
      margin-top: -30rem;
      &:last-child {
        .pedestal {
          max-height: 33rem;
        }
      }
    }
    .plaque {
      @apply w-82 h-52 -translate-y-40;
    }
    .pedestal {
      -webkit-mask-image: linear-gradient(to top, transparent, black var(--mask-height));
      mask-image: linear-gradient(to top, transparent, black var(--mask-height));
      --mask-height: 5rem;
    }
  }
}